.team-div {
	display: flex;
	justify-content: space-around;
}
.team {
	text-align: center;
}
.profile-display-pic {
	width: 170px;
	height: 170px;
	border-radius: 50%;
}
.team-soc ul {
	margin: 0;
	padding: 0;
	list-style: none;
}
.team-soc ul li {
	height: 25px;
	width: 20px;
	margin: 0 auto;
}
.team-soc ul li i {
	padding: 5px 7px;
	background: #347ab7;
	color: #fff;
	width: 27px;
	border-radius: 50px;
	height: 27px;
}
