.fa-angle-right {
	font-size: 24px;
	width: 20px;
	font-weight: 900;
	align-self: center;
}
.footer-logo img {
	width: 50%;
	margin-bottom: 10px;
}
.footer-links {
	margin-right: 10px;
	display: flex;
	font-size: 1.2rem;
}
.footer-link-offc {
	color: #333652;
	margin-bottom: 0;
	cursor: pointer;
}
.social-icon {
	background-color: #333652;
	padding: 3px;
	width: 27px;
	border-radius: 50%;
	height: 27px;
	text-align: center;
	cursor: pointer;
	margin: 0 20px;
}
.social-icon a {
	color: none !important;
	text-decoration: none !important;
}
.social-icon i {
	color: #fff;
}
.social-icon:hover {
	background-color: #fad02c;
}
.social-div {
	display: flex;
	justify-content: center;
}

@media screen and (max-width: 860px) {
	.footer-links {
		font-size: 1rem;
	}
	.social-icon {
		margin: 0 20px;
	}
}
