.contact-form-bg {
	background-color: #333652;
	padding: 6% 0;
	width: 100%;
}
.contact-form-div {
	z-index: 1;
	width: 55%;
	padding: 30px 20px;
	margin: auto;
	background-color: #fff;
}
.form-title {
	font-size: 1.6em;
	font-weight: 600;
	color: #333652;
	text-align: center;
}
.form-subtitle {
	/* font-size: 1.2em; */
	font-weight: 500;
	color: #333652;
	text-align: center;
}
.contact-form p {
	margin-bottom: 5px;
}
.req {
	color: rgb(194, 6, 6);
}
.form-input {
	padding: 8px 5px;
	width: 100%;
	border: 3px solid #eeeeee;
	height: 50px;
}
.form-textarea {
	padding: 8px 5px;
	width: 100%;
	border: 3px solid #eeeeee;
}
.inflex-justify {
	width: 100%;
	display: inline-flex;
	justify-content: space-between;
}
.submit-form {
	background: transparent;
	padding: 10px 50px;
	/* width: 30%; */
	border-radius: 30px;
	text-transform: uppercase;
	border: 2px solid #fad02c;
}
.submit-form:hover {
	color: #fff;
	border: 2px solid #333652;
	background-color: #333652;
	transition: all 0.4s ease-in-out;
}
.form-radio {
	width: 25px;
	height: 25px;
}
.form-checkbox {
	width: 25px;
	height: 25px;
}
.form-checkbox-text {
	padding-left: 10px;
}
.enquiry {
	text-align: center;
}

@media screen and (max-width: 860px) {
	.contact-form-div {
		width: 90%;
		padding: 15px 10px;
	}
	.form-title {
		font-size: 1.2em;
	}
	.form-checkbox {
		width: 50px;
		height: 50px;
	}
}
