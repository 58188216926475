.para-title {
	font-size: 2.2em;
	line-height: 1.3em;
	color: #333652;
	font-weight: 700;
}
.section-title {
	color: #333652;
	font-weight: 700;
}
.vert-al {
	align-self: center;
}
.para-subtitle {
	padding-bottom: 10px;
	color: #333652;
}
.section-content-im {
	width: 100%;
	display: flex;
	flex-direction: row-reverse;
}
.gitco-package-div {
	padding: 20px 10px;
	color: #fff;
	text-align: center;
	background: #333652;
}
.click-here {
	background: transparent;
	padding: 7px 30px;
	font-size: 1rem;
	border-radius: 30px;
	text-transform: uppercase;
	color: #fff;
	margin: 0 0 0 10px;
	border: 2px solid #fad02c;
}
.fade-div {
	max-height: 282px;
	min-height: 150px;
	-webkit-transition: max-height 1s;
	transition: max-height 1s;
	overflow-y: hidden;
	position: relative;
}
.fade-div::after {
	content: "";
	position: absolute;
	bottom: 0px;
	width: 100%;
	height: 75px;
	left: 0px;
	background: -webkit-linear-gradient(rgba(255, 255, 255, 0) 0%, white 100%);
	background-image: linear-gradient(rgba(255, 255, 255, 0) 0%, white 100%);
}
.read {
	margin: 30px 0 0 30px;
	font-weight: 700;
	cursor: pointer;
	background: none;
	border: none;
	color: #333652;
}
.modal-content {
	width: 72%;
	margin: auto;
	border-radius: 10px;
	border: none;
}
.modal-header {
	justify-content: flex-end;
	padding: 5px;
}
.modal-body {
	padding: 0;
}
.modal-footer {
	justify-content: center !important;
	border: none;
}
.close-popup {
	background: none;
	border: 1px solid lightgray;
	border-radius: 5px;
	font-size: 1.3rem;
	color: gray;
}

@media screen and (max-width: 1150px) and (min-width: 991px) {
	.para-title {
		font-size: 1.7em;
	}
}

@media screen and (max-width: 990px) {
	.para-title {
		font-size: 1.5em;
	}
	.modal-content {
		width: 100%;
	}
}

@media screen and (max-width: 454px) {
	.para-title {
		font-size: 1.5em;
	}
	.click-here {
		margin: 20px 0 0 0;
	}
}
