:root {
	font-family: "Montserrat", sans-serif;
	margin: 0 !important;
}
body {
	font-family: "Montserrat", sans-serif;
	margin: 0 !important;
}
.row {
	margin: 0;
}
.flex-sb{
    display: flex;
    justify-content: space-between;
}
.text-center {
	text-align: center;
}
.text-underline {
	text-decoration: underline;
}
.main-wrapper {
	padding: 0 14%;
}
.no-pad {
	padding: 0;
}
.pd-5 {
	padding: 5px;
}
.pd-10 {
	padding: 10px;
}
.pd-15 {
	padding: 15px;
}
.pd-20 {
	padding: 20px;
}
.pd-30 {
	padding: 30px;
}
.pd-tb-5 {
	padding: 5px 0;
}
.pd-tb-10 {
	padding: 10px 0;
}
.pd-tb-20 {
	padding: 20px 0;
}
.pd-tb-30 {
	padding: 30px 0;
}
.pd-tb-40 {
	padding: 40px 0;
}
.pd-tb-50 {
	padding: 50px 0;
}
.pd-lr-5 {
	padding: 0 5px;
}
.pd-lr-10 {
	padding: 0 10px;
}
.pd-lr-20 {
	padding: 0 20px;
}
.pd-lr-30 {
	padding: 0 30px;
}
.pd-lr-40 {
	padding: 0 40px;
}
.pd-lr-50 {
	padding: 0 50px;
}
.pd-t-5 {
	padding: 5px 0 0 0;
}
.pd-t-10 {
	padding: 10px 0 0 0;
}
.pd-t-15 {
	padding: 15px 0 0 0;
}
.pd-t-20 {
	padding: 20px 0 0 0;
}
.pd-t-30 {
	padding: 30px 0 0 0;
}
.pd-t-40 {
	padding: 40px 0 0 0;
}
.pd-b-5 {
	padding: 0 0 5px 0;
}
.pd-b-10 {
	padding: 0 0 10px 0;
}
.pd-b-15 {
	padding: 0 0 15px 0;
}
.pd-b-20 {
	padding: 0 0 20px 0;
}
.pd-b-25 {
	padding: 0 0 25px 0;
}
.fw-600 {
	font-weight: 600;
}
.align-element {
	display: flex;
	align-items: center;
}

@media screen and (max-width: 860px) {
	.main-wrapper {
		padding: 0 3%;
	}
	.pd-tb-40 {
		padding: 20px 0;
	}
	.pd-tb-50 {
		padding: 30px 0;
	}
}
