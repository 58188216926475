.hotel-wrapper {
    margin: 0 11%;
}
.hotel-card {
    border-radius: 10px;
    height: 100%;
    box-shadow: 0 6px 14px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transition: transform 0.3s ease;
}
.hotel-card:hover {
    transform: translateY(-8px);
}
.hotel-feature {
    border-radius: 10px 10px 0 0;
    width: 100%;
    height: 250px;
    object-fit: cover; 
}
.hotel-name {
    font-size: 17px;
    font-weight: 600;
    color: #333652;
}
.hotel-info {
    margin-bottom: 0;
}
.hotel-category {
    font-size: 14px;
    border: 1px solid;
    border-radius: 5px;
    background-color: teal;
    color: white;
    font-weight: 500;
    padding: 0 5px;
    height: fit-content;
}
.hotel-amount {
    font-size: 22px;
    font-weight: 600;
    color: rgb(6, 144, 66);
}
.tax {
    font-size: 11px;
    text-align: end;
    margin-top: -5px;
}
.hotel-distance {
    font-size: 14px;
    margin-bottom: 10px;
    font-weight: 600;
    color: #333652;
}
.distance {
    font-size: 15px;
    font-weight: 600;
    color: rgb(242, 174, 106);
}
.hotel-amenities {
    margin-top: 5px;
}
.amenities-label {
    font-weight: 600;
    font-size: 15px;
    color: #333652;
}
.amenities {
    font-size: 12px;
    border-radius: 5px;
    background-color: teal;
    color: white;
    font-weight: 500;
    padding: 2px 10px;
    margin: 4px;
    font-weight: 600;
}
.cancel-date {
    margin-bottom: 5px;
    font-size: 14px;
    text-align: center;
    color: teal;
}
.cancel-date i {
    font-weight: 600;
}
.request-cta {
    background-color: #333652;
    border: none;
    cursor: pointer;
}
.request-cta:hover {
    background-color: #333652;
}
.request-cta:focus {
    color: #fff;
    background: #333652 !important;
    border-color: none !important;
    box-shadow: none !important;
}
.modal-content {
    padding: 15px;
    width: 100%;
}
.headline {
    font-size: 24px;
    font-weight: 600;
}
.sub-headline {
    padding: 15px 5px 0 5px;
    font-size: 18px;
    font-weight: 600;
    margin: 0;
}
.field-name {
    font-weight: 700;
}
.room-card {
    margin: 10px 0 15px 0;
}
.selected {
    border: 2px solid #ffffdb;
    background: #ffffdb;
}
.room-card-left { 
    border-right: 1px solid rgba(0, 0, 0, .125);
    height: 100%;
    position: relative;
    margin: 0 0px;
    left: 0%;
    top: 0;
}
.options { 
    border-bottom: 1px solid rgba(0, 0, 0, .125);
    height: 50%;
    position: relative;
    margin: 0 0px;
    left: 0%;
    top: 0;
}
.price-details {
    margin-top: 10px;
}
.room-photo {
    width: 100%;
    height: 120px;
    object-fit: cover;
    margin-bottom: 10px;
}
.select-cta {
    padding: 2px 20px;
    background: #fff;
    border-radius: 20px;
    border: 2px solid #fad02c;
    color: #fad02c;
}
.active-cta {
    background: #fad02c;
    border: 2px solid #fad02c;
    color: #fff;
}
.submit-form {
	background: transparent;
	padding: 10px 50px;
	border-radius: 30px;
	text-transform: uppercase;
	color: #fff;
	border: 2px solid #333652;
	background-color: #333652;
    cursor: pointer;
}
.cancel {
    background: transparent;
	text-transform: uppercase;
	border: none;
    color: tomato;
    cursor: pointer;
    margin-right: 5%;
}
.footnote p {
    margin-bottom: 5px;
    font-size: 16px;
}
.title-brand {
    margin-bottom: 15px !important;
}
input[type="checkbox"] {
    transform: scale(1.6); /* Increase the size */
    margin: 0 8px 15px 3px;
}
.bottom-cta {
    background: none;
    color: #333652;
    border: none;
    cursor: pointer;
    padding: 0;
    font-weight: 700;
}
.bottom-cta:hover {
    color: #333652;
    background: none !important;
}
.bottom-cta:focus {
    color: #333652;
    background: none !important;
    border-color: none !important;
    box-shadow: none !important;
}