.navbar {
	height: 130px;
	display: flex;
	background-color: #fff;
	justify-content: center;
	align-items: center;
	font-size: 1.2rem;
	position: sticky;
	padding: 10px 0;
	top: 0;
	z-index: 999;
}
.navbar-container {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 110px;
	max-width: 1500px;
}
.navbar-logo .brand-logo {
	width: 120px;
	transition: all 0.6s cubic-bezier(0, 0, 0.2, 1);
}
.resize-logo {
	transform: scale(0.8);
}
.resize-div {
	height: 120px;
	transition: all 0.2s ease-in;
}
.fa-typo3 {
	margin-left: 0.5rem;
	font-size: 1.8rem;
	color: #333652;
}
.nav-menu {
	display: grid;
	grid-template-columns: repeat(4, auto);
	grid-gap: 10px;
	list-style: none;
	text-align: center;
	width: 68vw;
	justify-content: end;
}
.nav-item {
	/* height: 0px; */
	padding: 5px 0;
}
.nav-links {
	color: #333652;
	display: flex;
	align-items: center;
	text-decoration: none;
	cursor: pointer;
	height: 100%;
	font-weight: 600;
}
.nav-links:hover {
	color: #fad02c;
	/* border-bottom: 1px solid #fad02c; */
	transition: all 0.2s ease-out;
}
.nav-sublink {
	color: #333652;
	align-items: center;
	text-decoration: none;
	cursor: pointer;
	height: 100%;
	font-weight: 600;
}
.nav-sublink:hover {
	color: #fad02c;
}
.desktop-sublink {
	color: black;
	padding: 30px 16px 0 16px;
	text-decoration: none;
	display: block;
	font-weight: 600;
	font-size: 16px;
}
.desktop-sublink:hover {
	color: black;
}
.corp-sublink {
	color: #333652;
	margin-left: 20px;
	font-size: 16px;
	width: 100%;
	text-decoration: none;
}
.dropdown {
	position: relative;
	display: inline-block;
}
.dropdown-content {
	display: none;
	top: 40px;
	left: -100px;
	padding: 20px 0;
	position: absolute;
	background-color: #fff;
	min-width: 300px;
	box-shadow: 0px 8px 16px 0px rgb(0 0 0 / 20%);
	z-index: 40;
}
.shadow {
	box-shadow: 0px 5px 15px 0px rgb(0 0 0 / 20%);
}
/* .dropdown-content a {
	color: black;
	padding: 12px 16px;
	text-decoration: none;
	display: block;
	font-weight: 600;
	font-size: 16px;
} */

.dropdown-content a:hover {
	color: #fad02c;
}
.dropdown:hover .dropdown-content {
	display: block;
}
.dropdown:hover .dropbtn {
	background-color: #3e8e41;
}
.fa-bars {
	color: #333652;
}
.nav-links-mobile {
	display: none;
}
.menu-icon {
	display: none;
}
.mobile-route-link {
	text-decoration: none;
	color: #333652;
}
.active-link {
	color: #fad02c;
}

@media screen and (max-width: 860px) {
	.navbar {
		height: 140px;
		top: -5px;
	}

	.resize-div {
		height: 134px;
		transition: all 0.2s ease-in;
	}

	.NavbarItems {
		position: relative;
	}

	.nav-menu {
		display: flex;
		flex-direction: column;
		width: 100%;
		/* height: 90vh; */
		position: absolute;
		top: 144px;
		left: -100%;
		opacity: 1;
		transition: all 0.5s ease;
	}

	.nav-menu.active {
		background: #fff;
		width: 104vw;
		top: 134px;
		left: -15px;
		padding: 10px 0;
		opacity: 1;
		transition: all 0.5s ease;
		z-index: 1;
	}

	.nav-links {
		text-align: left;
		font-size: 1.5rem;
		width: 95%;
		display: table;
	}

	.nav-links:hover {
		/* background-color: #fad02c; */
		color: #242424;
		border-radius: 0;
	}

	.navbar-logo {
		position: absolute;
		top: 0px;
		left: 30px;
		/* transform: translate(100%, -3%); */
	}

	.menu-icon {
		display: block;
		position: absolute;
		top: 0;
		right: 0;
		transform: translate(-100%, 80%);
		font-size: 1.8rem;
		cursor: pointer;
	}

	.fa-times {
		color: #333652;
		font-size: 2rem;
	}

	.nav-links-mobile {
		display: block;
		text-align: center;
		margin: 2rem auto;
		border-radius: 4px;
		width: 80%;
		text-decoration: none;
		font-size: 1.5rem;
		background-color: transparent;
		color: #fff;
		padding: 14px 20px;
		border: 1px solid #fff;
		transition: all 0.3s ease-out;
	}

	.nav-links-mobile:hover {
		background: #fff;
		color: #242424;
		transition: 250ms;
	}

	.dropdown-content {
		display: none;
		top: 40px;
		left: -85%;
	}

	.brand-logo {
		margin-top: 15px;
	}
}
